<template>
  <GModal name="modal" class="modal modal-confirm-delete-product">
    <div slot="header">
      <div class="modal-title">
        <div class="text-red">Hapus produk?</div>
        <div class="text">Yakin ingin menghapus produk ini?</div>
      </div>
    </div>
    <div slot="body">
      <div class="confirmed-delete-product" @click="deleteProduct">
        <span>Ya, hapus</span>
      </div>
      <div class="cancel-delete-product" @click="$emit('close')">
        <span>Batalkan</span>
      </div>
    </div>
  </GModal>
</template>

<script>
export default {
  name: 'ModalConfirmDeleteProduct',
  props: {
    indexProduct: {
      type: Number,
      default: -1,
    },
  },
  methods: {
    deleteProduct() {
      this.$emit('delete-product', this.indexProduct);
    },
  },
};
</script>
